import '../styles/Pages.css'
import PageTransition from '../comp/PageTransition'
import { useState, useEffect } from 'react'


function About(){

    const message = "Design-driven, logic-focused. "
    const [showText, setShowText] = useState(false)

    useEffect( () => {
        const timer = setTimeout(
            () => {setShowText(true)}, 2300
        )

        return () => clearTimeout(timer)

    }, []
    )

        return(
    
            <PageTransition typewriterText={message}>
            <div id="home">
                <p className={` current-text ${ showText ? 'fadein' : '' } `}>
                After my studies in Interaction Design and Visual Communication, I worked as a freelancer on both in-house and client-based projects. I’ve as well expanded my expertise by developing programming skills, bridging the gap between creative design and logical problem-solving.
                <br/><br/>
                <a href='/../murielhediger_resume_2024.pdf' rel="noopener noreferrer" target="_blank">Download my resume</a>
                </p>
            </div>
            </PageTransition>
        )
    }



export default About