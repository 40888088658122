import { useEffect, useRef } from "react"
import '../styles/PageTransition.css'
import useTypewriter from "../hooks/useTypewritter";

function PageTransition({children, typewriterText, delay = 500}){

    const pageRef = useRef(null)

    const typedText = useTypewriter(typewriterText, 40, 500);

    useEffect(() => {
        const page = pageRef.current
        page.classList.add('fadein')
       
    }, [ ])

    return(
        <div ref={pageRef} className='page'>
                        <h1>{typedText}</h1>
            {children}

        </div>
    )
}

export default PageTransition