import PageTransition from '../comp/PageTransition'
import '../styles/Pages.css'
import { useState, useEffect } from 'react'

function Contact (){
    
    const message = "So call me, maybe... "
    const [showText, setShowText] = useState(false)

    useEffect( () => {
        const timer = setTimeout(
            () => {setShowText(true)}, 2200
        )

        return () => clearTimeout(timer)

    }, []

    )   

        return(
    
            <PageTransition typewriterText={message}>
            <div id="home">
                <p className={`current-text ${ showText ? "fadein" : "" }`}>
                <br/>
                    <a href='mailto:muriel.hediger@icloud.com'>muriel.hediger@icloud.com</a><br/><br/>
                    +41 794714831 <br/><br/>
                    Muriel Hediger<br/>
                    Via S. Balestra 39<br/>6900 Lugano<br/>
                </p>
            </div>
            </PageTransition>
        )
    }

export default Contact