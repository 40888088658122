import { useEffect, useState } from 'react';

function useTypewriter(text, speed = 50, delay = 500) {
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        let index = 0;
        let interval;

        const timer = setTimeout(() => {
            interval = setInterval(() => {
                setDisplayedText(prev => {
                    index++;
                    if (index <= text.length) {
                        return text.slice(0, index);
                    } else {
                        clearInterval(interval);
                        return prev;
                    }
                });
            }, speed);
        }, delay);

        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
    }, [text, speed, delay]);

    return displayedText;
}

export default useTypewriter;