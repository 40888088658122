import '../styles/Pages.css'
import PageTransition from '../comp/PageTransition'
import { useState, useEffect } from 'react'

function Home(){

    const message = "Hello, my name is Muriel, I am a product designer. "
    const [showText, setShowText] = useState(false)

    useEffect ( () => {
        const timer = setTimeout( () => {
            setShowText(true)
        }, 2800)

        return () => clearTimeout(timer)
    },[]
    )

    return(

        <PageTransition typewriterText={message}>
        <div id="home">
            <p className={`current-text ${ showText ? 'fadein' : '' }`}>
            With over 10 years of experience, I've built up a wide range of skills 💪
            from UX to Interaction Design, along with some solid team management know-how and a developer-friendly approach. 👩‍💻❤️<br/><br/> 
            </p>
        </div>
        </PageTransition>
    )
}

export default Home

