import { NavLink } from 'react-router-dom';
import '../styles/Nav.css';
import styled from 'styled-components'

const NavContainer = styled.ul`
flex: 1;
list-style: none;
`

const StyledLink = styled(NavLink)`
text-decoration: none;
color: inherit;
transition: opacity 0.3s ease, font-size 0.5s ease;

&:hover {
    opacity: 0.2;
}

&.active {
font-size: 90px;
}

@media screen and (max-width: 768px) {
  &.active {
    font-size: 60px;
  }
}

@media screen and (max-width: 375px) {
  &.active {
    font-size: 60px;
  }
}
`

function Nav(){
    return(
        <NavContainer>
            <li><StyledLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Ciao!</StyledLink></li>
            <li><StyledLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>About</StyledLink></li>
            <li><StyledLink to="/work" className={({ isActive }) => (isActive ? 'active' : '')}>Work</StyledLink></li>
            <li><StyledLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')}>Contact</StyledLink></li>
        </NavContainer>
    );
}

export default Nav