import '../styles/Pages.css'
import PageTransition from '../comp/PageTransition'
import { useState, useEffect } from 'react'


function Work(){

    const message = "I am open to work ✨  "
    const [showText, setShowText] = useState(false)

    useEffect( () => {
        const timer = setTimeout(
            () => {setShowText(true)}, 2200
        )

        return () => clearTimeout(timer)

    }, []
    )

        return(
    
            <PageTransition typewriterText={message}>
            <div id="home">
                <p className={` current-text ${ showText ? 'fadein' : '' } `}>
                    My current portfolio is on Figma: <br/>
                <a href='https://www.figma.com/proto/iddGniBHX0LrV19mGXHAXR/UX-%2F-UI-Portfolio?node-id=3-77&starting-point-node-id=3%3A77&scaling=scale-down&content-scaling=fixed&t=XH0lh54ZAa9lWh1W-1' rel="noopener noreferrer" target="_blank">Muriel Hediger Portfolio</a>
                </p>
            </div>
            </PageTransition>
        )
    }



export default Work